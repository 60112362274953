//正式版

export const approveUsdtAddr = '0x55d398326f99059fF775485246999027B3197955';
export const approveBTCAddr = '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c';
export const approveETHAddr = '0x2170ed0880ac9a755fd29b2688956bd959f933f8';
export const approveFILAddr = '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153';
export const approveBUSDAddr = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
export const approveUSDCAddr = '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d';
export const approveWBNBAddr = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';

export const moreWalletAddr = '0xd856D338DD558b40EE879D2EA9855cf0549fA6b6';

export const W3CAddr = '0x2fa6ee42BacF983F050210A1ca42f88686327FC9';
export const W3BAddr = '0x6EdAD34A4b18A5C2C2abb9e00dFeF4C6d3205a20';

export const stackeAddr = '0xAB56805cD44fdd45f5b6652d95108392aa07E823';

export const wrapAddr = '0xb0Af49B41b4a843D8a9306d11aCF9B347793fA1c';

export const mintAddr = '0x270D48cE55E34A956886bB266218b08957Deb445';
export const relationAddr = '0xe9894034d2fC5C5A2A9A0f7504B2a702D52D7626';
export const bullvcAddr = '0x264D7D9d6EEA163a183A0Bc9e57d1Da3F8560ba5';
export const nftAddr = '0xeA76CA309fA140B5f4d469B1462d55e164062D64';

export const totalAmount = 1000000000000;

export const inviteUrl = 'https://w3dao.finance/#/bind/';





//BSC老版本

// export const approveUsdtAddr = '0x55d398326f99059fF775485246999027B3197955';
// export const approveBTCAddr = '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c';
// export const approveETHAddr = '0x2170ed0880ac9a755fd29b2688956bd959f933f8';
// export const approveFILAddr = '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153';
// export const approveBUSDAddr = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
// export const approveUSDCAddr = '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d';
// export const approveWBNBAddr = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';

// export const moreWalletAddr = '0xF93CBe62c4F726613C7E2403a2676d51D69E9455';

// export const W3CAddr = '0xd551Db5393629F1610648915de6A07bBA59Cee2e';
// export const W3BAddr = '0x8C9dD651B6095E652B28E38c154b1a6a4b057431';
// export const stackeAddr = '0x1D2403C2baFd1FcAa0Daca2ba1928556b2ED4E72';
// export const wrapAddr = '0x482Ff1dE31a672B839c11F399D0C88692368D37b';

// export const mintAddr = '0x098256Fea49dd4D86608f4Be5774497F9a0908a5';
// export const relationAddr = '0x72EBF8113162c1f670faC5453f7fdBBbc4C40ba6';
// export const bullvcAddr = '0x10735D07d59A5B4f6f48CdD54DD7fC7d09b34bf2';
// export const nftAddr = '0x2bF5AAa70FAEc5393c10Ca1c80C38d1599C05545';

// export const W3CUSDTAddr = '0x7111fc066ce477f220bd535ecbac3632a5e9a518';

// export const totalAmount = 1000000000000;

// export const inviteUrl = 'https://saynodao.com/w3b/#/bind/';






// 测试服
// export const approveUsdtAddr = '0x6A14a8ad4c3a4830eA969e073161847DC9cd52a8';
// export const nftAddr = '0x8845e06e28faf62Fb1d21F0075151b9193dB9b57';
// export const bullvcAddr = '0x2fE05b2318dAbD6CbA1e9A40E4BD1270EcB1D364';
// export const relationAddr = '0x016cCBC3218a9A86252Ce1856060cBC10416C834';
// export const mintAddr = '0x2987d06aEB08133aF1a24e372161571843E08845';

// export const W3CAddr = '0x775AF5Eb3989DEA32C4598a15accc0b190b54797'; //CRS
// export const W3BAddr = '0xbb7f5a65FD2dc3c98EB4150bBdCDA732868Dbd3F';//CES
// export const stackeAddr = '0xc20E83b224255FdeF45dB60A1BCd9F49deDDFa4e';
// export const wrapAddr = '0xAbD4CA42332c5d933FBd73FC44cdb2c7d8b5aF6B';

// export const moreWalletAddr = '0x2fE05b2318dAbD6CbA1e9A40E4BD1270EcB1D364';


// export const totalAmount = 1000000000000;

// export const inviteUrl = 'https://saynodao.com/w3b/#/bind/';

export const airdropAddr = '0xaC59005D7fc3ABccA576a9e76443B80b066e2Ad0';
export const testAirdropAddr = '0x8d1D935B1798ABd9f00d6ED117Ec842afa57C4B7';