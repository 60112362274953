<template>
	<div class="ls-modal-bg ls-flex-ajc" v-if="fatherVal.showState">
		<div class="ls-flex-ajb ls-modal">
			<img v-if="fatherVal.State==1" class="state-icon rotate-icon" :src="require('../../assets/img/icon/icon_wallet.png')" alt="">
			<div v-if="fatherVal.State==1">
				<div class="modal-title">Waiting for Confirmation</div>
				<div class="modal-state">{{fatherVal.StateInfo}}</div>
				<div class="modal-info">Confirm this transaction in you wallet</div>
			</div>
			<img v-if="fatherVal.State==2" class="state-icon" :src="require('../../assets/img/icon/icon_success.png')" alt="">
			<div v-if="fatherVal.State==2">
				<div class="modal-title">Transaction Success</div>
				<div class="modal-state"><span>Transaction Hash</span> : {{transactionHash}}</div>
				<div class="modal-info" style="cursor: pointer;text-decoration: underline;" @click="openBscScan(2)">ViewOn BscScan</div>
			</div>
			<img v-if="fatherVal.State==3" class="state-icon" :src="require('../../assets/img/icon/icon_fail.png')" alt="">
			<div v-if="fatherVal.State==3">
				<div class="modal-title">Transaction Error</div>
				<div class="modal-state">{{fatherVal.StateInfo}}</div>
				<div class="modal-info" style="cursor: pointer;text-decoration: underline;" @click="openBscScan(3)">ViewOn BscScan</div>
			</div>
			<img class="close-icon" @click="closeModal()" :src="require('../../assets/img/icon/icon_c.png')" alt="">
		</div>
	</div>
	
</template>

<script setup>
	import {ref,watch} from 'vue';
	import {useStore} from 'vuex';
	import formate from "@/utils/formate.js";
	const store = useStore();
	
	const fatherVal = ref(null);
	const transactionHash = ref('');
	
	const props = defineProps({
		State:Number,
		showState:Boolean,
		StateInfo:String,
		tHash:String
	})
	
	fatherVal.value = props;
	
	watch([props],([news,newa],[olds,olda])=>{
		fatherVal.value = news;
		transactionHash.value =formate.formatAddr(news.tHash);
	})
	
	const openBscScan = (state)=>{
		if(state==2){
			window.open('https://bscscan.com/tx/'+fatherVal.value.tHash);
		}
		else{
			window.open('https://bscscan.com/error.html');
		}
	}
	
	const closeModal = ()=>{
		fatherVal.value.showState = false;
	}
		
</script>

<style scoped>
	.ls-modal-bg{
		position: fixed;
		left: 0%;
		top: 0;
		z-index: 999999999;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.4);
	}
	.ls-modal-bg .ls-modal{
		width: 600px;
		padding: 25px;
		box-sizing: border-box;
		background-color: rgba(98,0,0,1);
		border-radius: 10px;
	}
	.ls-modal .state-icon{
		width: 60px;
		margin-right: 25px;
	}
	.ls-modal .close-icon{
		width: 60px;
		margin-left: 25px;
		cursor: pointer;
	}
	.ls-modal .modal-title{
		font-size: 32px;
		color: #999;
		font-weight: 500;
	}
	.ls-modal .modal-state{
		margin: 25px 0 15px;
		font-size: 24px;
		color: #fff;
	}
	.ls-modal .modal-info,.ls-modal .modal-state span{
		color: #ccc;
		font-size: 18px;
	}
	.rotate-icon{
		animation:myfirst 2.5s infinite;
		-moz-animation:myfirst 2.5s infinite; /* Firefox */
		-webkit-animation:myfirst 2.5s infinite; /* Safari and Chrome */
		-o-animation:myfirst 2.5s infinite; /* Opera */
	}
	@keyframes myfirst
	{
	    0%   {transform: rotateY(0deg);}
	    50%  {transform: rotateY(180deg);}
	    100% {ransform: rotateY(360deg);}
	}
	 
	@-webkit-keyframes myfirst /* Safari 与 Chrome */
	{
	   0%   {transform: rotateY(0deg);}
	   50%  {transform: rotateY(180deg);}
	   100% {ransform: rotateY(360deg);}
	}
	
	@media (max-width:768px) {
		.ls-modal-bg .ls-modal{
			width: 95%;
			padding: 25px 10px 35px 15px;
		}
		.ls-modal .state-icon{
			width: 40px;
			margin-right: 15px;
		}
		.ls-modal .close-icon{
			width: 48px;
			margin-left: 15px;
		}
		.ls-modal .modal-title{
			font-size: 24px;
		}
		.ls-modal .modal-state{
			margin: 15px 0;
			font-size: 20px;
		}
		.ls-modal .modal-info,.ls-modal .modal-state span{
			color: #ccc;
			font-size: 14px;
		}
	}
</style>